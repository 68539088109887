import { SuccessSchema } from "contract/common";
import { initContract } from "@ts-rest/core";
import {
  AcceptOrDeclineInviteBody,
  CreateInviteRequest,
  ExcelFileUploadSchema,
  FileUploadErrorResponseSchema,
  GetAllVendorRequest,
  GetAllVendorResponse,
  GetVendorInviteResponse,
  GetVendorReponse,
  GetVendorRequest,
  UpdateVendorBody,
  VendorCalendarResponse,
  VendorSchema,
  BulkUploadSuccessSchema,
  AllRatingsSchema,
  GetVendorRatingsAndReviewsSchema,
  AddRatingSchema,
} from "contract/vendor/types";
import { z } from "zod";
import { createPaginatedResponseSchema } from "contract/utils";
import { ProPlannerView, RequestStatus } from "contract/enum";

const c = initContract();

export const vendorContract = c.router(
  {
    addVendor: {
      method: "POST",
      path: "/",
      responses: {
        200: SuccessSchema.extend({
          proPlannerInviteId: z.string().nullable(),
        }),
      },
      body: VendorSchema,
    },
    getVendorInfo: {
      method: "GET",
      path: "/",
      query: GetVendorRequest,
      responses: {
        200: GetVendorReponse,
      },
    },
    getVendorAllStarRatings: {
      method: "GET",
      path: "/ratings/star/all",
      query: z.object({
        vendorId: z.string(),
      }),
      responses: {
        200: AllRatingsSchema,
      },
    },
    getRatingsAndReviews: {
      method: "GET",
      path: "/ratings/all",
      responses: {
        200: createPaginatedResponseSchema(GetVendorRatingsAndReviewsSchema),
      },
      query: z.object({
        vendorId: z.string(),
        pageSize: z.coerce.number().min(1),
        pageNumber: z.coerce.number().min(1),
      }),
    },
    addRatings: {
      method: "POST",
      path: "/ratings",
      responses: {
        200: SuccessSchema,
      },
      body: AddRatingSchema,
    },
    updateVendorInfo: {
      method: "PUT",
      path: "/",
      responses: {
        200: SuccessSchema,
      },
      body: UpdateVendorBody,
    },
    getCategories: {
      method: "GET",
      path: "/categories",
      responses: {
        200: z.array(z.object({ categoryName: z.string(), id: z.string() })),
      },
      query: z.object({
        isProPlanner: z.coerce.boolean().optional(),
        searchText: z.string().optional(),
      }),
    },
    getVendors: {
      method: "GET",
      path: "/all",
      responses: {
        200: createPaginatedResponseSchema(GetAllVendorResponse),
      },
      query: GetAllVendorRequest,
    },

    getVendorInviteRequest: {
      method: "GET",
      path: "/invite",
      responses: {
        200: createPaginatedResponseSchema(
          GetVendorInviteResponse.extend({
            status: z.nativeEnum(RequestStatus),
            rejectionMessage: z.string().nullable(),
          })
        ),
      },
      query: z.object({
        pageNumber: z.coerce.number().min(1),
        pageSize: z.coerce.number().min(1),
      }),
    },
    getVendorInviteRequestForUser: {
      method: "GET",
      path: "/invite/user",
      responses: {
        200: createPaginatedResponseSchema(
          GetVendorInviteResponse.extend({
            status: z.nativeEnum(RequestStatus),
            rejectionMessage: z.string().nullable(),
          })
        ),
      },
      query: z.object({
        pageNumber: z.coerce.number().min(1),
        pageSize: z.coerce.number().min(1),
        status: z.nativeEnum(RequestStatus).optional(),
        projectId: z.string(),
      }),
    },
    createVendorInviteRequest: {
      method: "POST",
      path: "/invite",
      responses: {
        200: SuccessSchema,
      },
      body: CreateInviteRequest,
    },
    acceptOrDeclineInviteRequest: {
      method: "PATCH",
      path: "/invite",
      responses: {
        200: SuccessSchema,
      },
      body: AcceptOrDeclineInviteBody,
    },
    getCalendorVendorMeetings: {
      method: "GET",
      path: "/meeting",
      responses: {
        200: z.array(VendorCalendarResponse),
      },
      query: z.object({
        monthIndex: z.coerce.number().min(1).max(12),
        yearIndex: z.coerce.number().min(1),
      }),
    },
    deleteVendorMeeting: {
      method: "DELETE",
      path: "/meeting",
      responses: {
        200: SuccessSchema,
      },
      body: z.object({
        meetingId: z.string(),
      }),
    },
    updateMeeting: {
      method: "PATCH",
      path: "/meeting",
      responses: {
        200: SuccessSchema,
      },
      body: z.object({
        meetingId: z.string(),
        isCompleted: z.boolean(),
      }),
    },
    vendorProPlannerInviteEligilityCheck: {
      method: "POST",
      path: "/pro-planner-invite-eligibility",
      responses: {
        200: z.object({
          isEligibleToInvite: z.boolean(),
        }),
      },
      body: z.object({
        email: z.string().email(),
      }),
    },
    updateProPlannerSubVendor: {
      method: "PUT",
      path: "/pro-planner-sub-vendor",
      responses: {
        200: SuccessSchema,
      },
      body: z.object({
        id: z.string(),
        vendorName: z.string(),
        aboutCompanyAndMakeItEasierForPlanners: z.string(),
        category: z.array(
          z.object({
            id: z.string(),
            categoryName: z.string(),
          })
        ),
      }),
    },
    vendorProPlannerInviteConfirmation: {
      method: "POST",
      path: "/pro-planner-invite-accept",
      responses: {
        200: z.object({
          isRegistered: z.boolean(),
          isProPlannerDeleted: z.boolean(),
          isInviteRequestAlreadyAccepted: z.boolean(),
          isInviteRequestDeleted: z.boolean(),
        }),
      },
      body: z.object({
        inviteId: z.string(),
      }),
    },
    vendorProPlannerInviteStatusUpdation: {
      method: "POST",
      path: "/pro-planner-invite-status-update",
      responses: {
        200: SuccessSchema,
      },
      body: z.object({
        inviteId: z.string(),
        status: z.nativeEnum(RequestStatus),
      }),
    },
    vendorProPlannerInviteRequestInfo: {
      method: "GET",
      path: "/pro-planner-invite-request",
      responses: {
        200: z.object({
          id: z.string(),
          vendorName: z.string(),
          email: z.string(),
          proPlannerName: z.string(),
          categories: z.array(
            z.object({ categoryName: z.string(), id: z.string() })
          ),
        }),
      },
      query: z.object({
        inviteId: z.string(),
      }),
    },

    bulkUploadVendorData: {
      method: "POST",
      path: "/file",
      contentType: "multipart/form-data",
      responses: {
        200: z.discriminatedUnion("kind", [
          BulkUploadSuccessSchema,
          FileUploadErrorResponseSchema,
        ]),
      },
      body: ExcelFileUploadSchema,
    },
    deleteProPlannerSubVendorDelete: {
      method: "DELETE",
      path: "/pro-planner-sub-vendor",
      responses: {
        200: SuccessSchema,
      },
      body: z.object({
        email: z.string().email(),
      }),
    },
    proPlannerWeddingUserWeddingInfo: {
      method: "POST",
      path: "/pro-planner/user-wedding/info",
      responses: {
        200: z.object({
          isWeddingCreated: z.boolean(),
          weddingId: z.string().nullable(),
        }),
      },
      body: z.object({
        email: z.string().email(),
      }),
    },
    proPlannerJoinWedding: {
      method: "POST",
      path: "/pro-planner/join-wedding",
      responses: {
        200: z.object({
          isWeddingCreated: z.boolean(),
          weddingId: z.string().nullable(),
          isProPlannerJoined: z.boolean().nullable(),
          isCurrentTheProPlanner: z.boolean().nullable(),
        }),
      },
      body: z.object({
        email: z.string().email(),
      }),
    },
    proPlannerSwitchView: {
      method: "POST",
      path: "/pro-planner/switch-view",
      responses: {
        200: SuccessSchema,
      },
      body: z.object({
        viewType: z.nativeEnum(ProPlannerView),
      }),
    },
  },
  {
    pathPrefix: "/vendor",
  }
);
