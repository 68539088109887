import { ToastStatus, useCustomToast } from "@/hooks/useToast";
import { Center, Skeleton } from "@mantine/core";
import Cookies from "js-cookie";
import isEmpty from "lodash/isEmpty";
import { useRouter } from "next/router";
import { createContext, useContext, useEffect, useState } from "react";

const logoutToastId = "logoutToastId";

const loginRestrictedPages: string[] = [
  "/profile/",
  "/chat/",
  "/create-wedding/",
  "/key-moments/",
  "/wedding-project",
  "/add-your-wedding-date",
  "/edit-your-wedding-date",
  "/pro-planner-join-wedding",
];

interface ContextInterface {
  isLoggedIn: boolean;
  refreshLoginState: () => void;
}

const LoginContext = createContext<ContextInterface>({
  isLoggedIn: false,
  refreshLoginState: () => {},
});

export const LoginProvider = ({ children }: { children: React.ReactNode }) => {
  const router = useRouter();
  const path = router.asPath;
  const { showToast } = useCustomToast();
  const [isLoginChecked, setIsLoginChecked] = useState<boolean>(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  const refreshLoginState = () => {
    const token = Cookies.get("connect.sid");
    setIsLoggedIn(!isEmpty(token));
    setIsLoginChecked(true);
  };

  useEffect(() => {
    refreshLoginState();
  }, [path]);

  useEffect(() => {
    if (Cookies.get("connect.sid")) {
      return;
    }
    if (
      loginRestrictedPages.some((pathName: string) => path.includes(pathName))
    ) {
      router.push("/");
      showToast({
        status: ToastStatus.error,
        id: logoutToastId,
        message: "Please log in to view this page.",
      });
    }
  }, [isLoggedIn, path]);

  if (
    router.pathname === "/" ||
    router.pathname === "/blog/[id]/[slug]" ||
    router.pathname === "/events/[slug]/[id]"
  ) {
    return <>{children}</>;
  }

  if (!isLoginChecked) {
    return (
      <Center h="100vh" p={{ base: 10, xs: 20, sm: 48 }}>
        <Skeleton h="100%" w="100%" style={{ borderRadius: "20px" }} />
      </Center>
    );
  }

  return (
    <LoginContext.Provider
      value={{
        isLoggedIn,
        refreshLoginState,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

export const useLogin = () => useContext(LoginContext);
