import { ProPlannerView, WeddingCreationRoute } from "contract/enum";
import { passwordValidationChecker } from "contract/utils";
import { z } from "zod";

export const EmailLoginSchema = z.object({
  email: z.string().email(),
  password: z.string().min(8),
  isVendorLogin: z.boolean().optional(),
  isProPlannerLogin: z.boolean().optional(),
});

export const EmailregisterSchema = z
  .object({
    firstName: z.string(),
    lastName: z.string(),
    phoneNumber: z.string(),
    countryCode: z.string(),
    zipCode: z.string(),
    confirmPassword: z.string(),
    email: z.string().email(),
    password: z.string(),
    phoneCode: z.string(),
    inviteId: z.string().optional(),
    proPlannerInviteId: z.string().optional(),
    weddingCreationRoute: z.nativeEnum(WeddingCreationRoute).nullable(),
    picture: z.string().nullable(),
  })
  .superRefine((v, ctx) => {
    if (v.confirmPassword !== v.password) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Confirm password should be same as new password.",
      });
    }

    if (v.password.length < 8) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Password should be more than 8 characters.",
      });
    }

    if (v.password.length > 30) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Password should be less than 30 characters.",
      });
    }
  });

export const GetCityResponse = z.array(
  z.object({
    id: z.string(),
    cityName: z.string(),
  })
);

export const ChangePasswordSchema = z
  .object({
    oldPassword: z.string(),
    newPassword: z.string(),
    repeatedPassword: z.string(),
  })
  .superRefine(passwordValidationChecker);

export const SetPasswordSchema = z
  .object({
    newPassword: z.string(),
    repeatedPassword: z.string(),
  })
  .superRefine(passwordValidationChecker);

export const forgetPasswordSchema = z
  .object({
    newPassword: z.string(),
    repeatedPassword: z.string(),
  })
  .superRefine(passwordValidationChecker);

export const SendForgetPasswordEmail = z.object({
  email: z.string().email(),
});

export const GetForgetPasswordResponse = z.object({
  email: z.string().email(),
  firstName: z.string(),
  lastName: z.string(),
});

export const EmailRegisterResponse = z.object({
  isSuccess: z.boolean(),
  weddingCreationRoute: z.nativeEnum(WeddingCreationRoute).nullable(),
});

export const EmailLoginResponse = z.object({
  isSuccess: z.boolean(),
  isPathChosen: z.boolean(),
  isKeyMomentsSelected: z.boolean(),
  proPlannerViewType: z.nativeEnum(ProPlannerView).nullable(),
});
